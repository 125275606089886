import { createZodDto } from "nestjs-zod/dto";
import { z } from "nestjs-zod/z";

export const generateCoverLetterSchema = z.object({
  jobDescription: z.string().min(1, "Job Description is required"),
  skills: z.string().min(1, "Skills are required"),
  hiringManager: z.string().optional(),
  hiringCompany: z.string().optional(),
  jobTitle: z.string().optional(),
});

export class GenerateCoverLetterDto extends createZodDto(generateCoverLetterSchema) {}
