import { t } from "@lingui/macro";
import { defaultSections } from "@reactive-resume/schema";
import { Button, Input, Label, RichInput } from "@reactive-resume/ui";
import { cn } from "@reactive-resume/utils";
import { Editor } from "@tiptap/core";
import { useRef, useState } from "react";

import { AiActions } from "@/client/components/ai-actions";
import { CoverLetterGeneratorDialog } from "@/client/pages/builder/sidebars/left/dialogs/cover-letter-genrator";
import { useResumeStore } from "@/client/stores/resume";

import { getSectionIcon } from "./shared/section-icon";

export const LetterDetailsSection = () => {
  const setValue = useResumeStore((state) => state.setValue);
  const section = useResumeStore(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (state) => state.resume.data.sections.letterDetails ?? defaultSections.letterDetails,
  );
  const [isOpen, setIsOpen] = useState(false);
  const editorRef = useRef<Editor>(null);

  return (
    <section id="summary" className="grid gap-y-6">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          {getSectionIcon("letterDetails")}
          <h2 className="line-clamp-1 text-3xl font-bold">{t`Letter Details`}</h2>
        </div>
      </header>

      <main className={cn(!section?.visible && "opacity-50")}>
        <div className="mb-5 grid gap-4 sm:grid-cols-2">
          <div className="col-span-2 space-y-1.5">
            <Label htmlFor="letterDetails.jobTitle">{t`Job Title`}</Label>
            <Input
              id="letterDetails.jobTitle"
              value={section?.jobTitle}
              placeholder={t`Job Title`}
              onChange={(event) => {
                setValue("sections.letterDetails.jobTitle", event.target.value);
              }}
            />
          </div>

          <div className="space-y-1.5">
            <Label htmlFor="letterDetails.hiringManager">{t`Hiring Manager`}</Label>
            <Input
              id="letterDetails.hiringManager"
              value={section?.hiringManager}
              placeholder={t`Hiring Manager`}
              onChange={(event) => {
                setValue("sections.letterDetails.hiringManager", event.target.value);
              }}
            />
          </div>

          <div className="space-y-1.5">
            <Label htmlFor="letterDetails.hiringCompany">{t`Hiring Company`}</Label>
            <Input
              id="letterDetails.hiringCompany"
              value={section?.hiringCompany}
              placeholder={t`Hiring Company`}
              onChange={(event) => {
                setValue("sections.letterDetails.hiringCompany", event.target.value);
              }}
            />
          </div>
        </div>
        <Label className="flex items-center justify-between">
          <span>{t`Letter Content`}</span>
          <Button
            variant="outline"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            {t`Generate`}
          </Button>
        </Label>
        <div className="mt-4">
          <RichInput
            ref={editorRef}
            content={section?.content}
            footer={(editor) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              editorRef.current = editor;
              return (
                <AiActions value={editor.getText()} onChange={editor.commands.setContent} />
              );
            }}
            onChange={(value) => {
              setValue("sections.letterDetails.content", value);
            }}
          />
        </div>
      </main>
      <CoverLetterGeneratorDialog
        hiringManager={section?.hiringManager}
        hiringCompany={section?.hiringCompany}
        jobTitle={section?.jobTitle}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onGenerateContent={(content) => {
          setValue("sections.letterDetails.content", content);
          editorRef.current?.commands.setContent(content);
        }}
      />
    </section>
  );
};
